const data = {
  name: 'IntroHeader',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            cta_link: {
              url: '/contact-us/',
            },
            cta_text: {
              html: '<p>Let&#39;s partner up</p>',
              text: "Let's partner up",
            },
            header_content: {
              text:
                'We are more than an agency. We want to be a team within your team, sharing knowledge, and helping one another grow to drive growth and push new boundaries. We are decent at video games too.',
              html:
                '<p>We are more than an agency. We want to be a team within your team, sharing knowledge, and helping one another grow to drive growth and push new boundaries. We are decent at video games too.</p>',
            },
            hero_image: {
              url: null,
              alt: null,
              localFile: null,
            },
            tagline: {
              html: '<p>A team within your team</p>',
              text: 'A team within your team',
            },
            title: {
              html: '<h1>About Woolly Mammoth</h1>',
              text: 'About Woolly Mammoth',
            },
          },
        },
      },
    },
  ],
};

export default data;
