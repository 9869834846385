const data = {
  name: 'OneColImageCard',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            author: {
              html: '',
              text: '',
            },
            author_position: {
              html: '',
              text: '',
            },
            card_content: {
              html: '<p></p>',
              text: '',
            },
            cta_link: {
              url: '',
            },
            cta_text: {
              html: '',
              text: '',
            },
            image: {
              url:
                'https://images.prismic.io/woolly-mammoth-v2/fcb02fc6-9e9b-4c49-ad6e-91554221d4ff_wm-services-header.jpg?auto=compress%2Cformat&rect=0%2C0%2C2880%2C1526&w=2000&h=1060',
              alt: null,
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    placeholder: {
                      fallback:
                        'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAcRHbDDijn//xAAbEAACAgMBAAAAAAAAAAAAAAACAwABEhMhMv/aAAgBAQABBQInYxhlFtChHrm3e0vX/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREv/aAAgBAwEBPwGVGT//xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQIBAT8BTZv/xAAZEAEAAwEBAAAAAAAAAAAAAAABABFBIQL/2gAIAQEABj8CK7eQsy51CE8mMZ//xAAbEAACAwEBAQAAAAAAAAAAAAAAAREhYTFx4f/aAAgBAQABPyFitFqaJiVQdrSOtJ4VL58LTohrCnof/9oADAMBAAIAAwAAABCP/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QRgxgv//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxAVDNt3/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBcYH/2gAIAQEAAT8QQ0mluE7fjajyqztKcPy8lKoWnSbEvFXABoqzirz1BQMBBP/Z',
                    },
                    images: {
                      fallback: {
                        src:
                          '/static/7f419f913a334866bcb06980a2994b86/1ef80/fcb02fc6-9e9b-4c49-ad6e-91554221d4ff_wm-services-header.jpg',
                        srcSet:
                          '/static/7f419f913a334866bcb06980a2994b86/afc39/fcb02fc6-9e9b-4c49-ad6e-91554221d4ff_wm-services-header.jpg 500w,\n/static/7f419f913a334866bcb06980a2994b86/a1986/fcb02fc6-9e9b-4c49-ad6e-91554221d4ff_wm-services-header.jpg 1000w,\n/static/7f419f913a334866bcb06980a2994b86/1ef80/fcb02fc6-9e9b-4c49-ad6e-91554221d4ff_wm-services-header.jpg 2000w',
                        sizes: '(min-width: 2000px) 2000px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/7f419f913a334866bcb06980a2994b86/eff4c/fcb02fc6-9e9b-4c49-ad6e-91554221d4ff_wm-services-header.webp 500w,\n/static/7f419f913a334866bcb06980a2994b86/a247e/fcb02fc6-9e9b-4c49-ad6e-91554221d4ff_wm-services-header.webp 1000w,\n/static/7f419f913a334866bcb06980a2994b86/d7332/fcb02fc6-9e9b-4c49-ad6e-91554221d4ff_wm-services-header.webp 2000w',
                          type: 'image/webp',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                      ],
                    },
                    width: 2400,
                    height: 1272,
                  },
                },
              },
            },
          },
        },
      },
    },
  ],
};

export default data;
