const data = {
  name: 'CardGrid',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            cards_icon: 'Man With Flag',
            dark_mode: true,
            title: {
              text: 'What we do ',
              html: '<p>What we do </p>',
            },
          },
          items: [
            {
              card_content: {
                html:
                  '<p>By employing behavioural analytics, development knowledge, and leading technical science to our design practices, we understand your users and can create engaging digital products suited to them.</p>',
                text:
                  'By employing behavioural analytics, development knowledge, and leading technical science to our design practices, we understand your users and can create engaging digital products suited to them.',
              },
              card_cta_link: {
                url: '/services/',
              },
              card_cta_text: {
                html: '<p>All Services</p>',
                text: 'All Services',
              },
              card_title: {
                html: '<h2>User Experience (UX) Design</h2>',
                text: 'User Experience (UX) Design',
              },
            },
            {
              card_content: {
                html:
                  '<p>We understand there&#39;s often a disconnect between design and development and things can often go awry. We are frontend specialists that stress the smaller details to deliver pixel-perfect products. </p>',
                text:
                  "We understand there's often a disconnect between design and development and things can often go awry. We are frontend specialists that stress the smaller details to deliver pixel-perfect products. ",
              },
              card_cta_link: {
                url: '/services/',
              },
              card_cta_text: {
                html: '<p>All Services</p>',
                text: 'All Services',
              },
              card_title: {
                html: '<h2>Frontend Web Development</h2>',
                text: 'Frontend Web Development',
              },
            },
            {
              card_content: {
                html:
                  '<p>Stable and robust applications consist of multiple layers. Each layer requires careful planning and consideration to futureproof a platform, and work alongside the tech powering your business.</p>',
                text:
                  'Stable and robust applications consist of multiple layers. Each layer requires careful planning and consideration to futureproof a platform, and work alongside the tech powering your business.',
              },
              card_cta_link: {
                url: '/services/',
              },
              card_cta_text: {
                html: '<p>All Services</p>',
                text: 'All Services',
              },
              card_title: {
                html: '<h2>Backend Web Development</h2>',
                text: 'Backend Web Development',
              },
            },
            {
              card_content: {
                html:
                  '<p>Every web app we build is built to the same high standards as a native application. Performant, accessible, and available on all network connections – even if you&#39;re offline!</p>',
                text:
                  "Every web app we build is built to the same high standards as a native application. Performant, accessible, and available on all network connections – even if you're offline!",
              },
              card_cta_link: {
                url: '/services/',
              },
              card_cta_text: {
                html: '<p>All Services</p>',
                text: 'All Services',
              },
              card_title: {
                html: '<h2>Web Application Development</h2>',
                text: 'Web Application Development',
              },
            },
            {
              card_content: {
                html:
                  '<p>Build feature-rich iOS and Android applications that deliver an experience that is native to the user&#39;s device, using the same coding languages as your web products.</p>',
                text:
                  "Build feature-rich iOS and Android applications that deliver an experience that is native to the user's device, using the same coding languages as your web products.",
              },
              card_cta_link: {
                url: '/services/',
              },
              card_cta_text: {
                html: '<p>All Services</p>',
                text: 'All Services',
              },
              card_title: {
                html: '<h2>Mobile App Development </h2>',
                text: 'Mobile App Development ',
              },
            },
            {
              card_content: {
                html:
                  '<p>We have worked extensively with providers like Shopify and Magento. We can support your existing setup or help you migrate to a new, more conversion-focused tech stack.</p>',
                text:
                  'We have worked extensively with providers like Shopify and Magento. We can support your existing setup or help you migrate to a new, more conversion-focused tech stack.',
              },
              card_cta_link: {
                url: '/services/',
              },
              card_cta_text: {
                html: '<p>All Services</p>',
                text: 'All Services',
              },
              card_title: {
                html: '<p>Ecommerce Development</p>',
                text: 'Ecommerce Development',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
