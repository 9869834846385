const data = {
  name: 'PlainCarousel',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            icon: null,
            tagline: {
              html: '',
              text: '',
            },
            title: {
              html: '',
              text: '',
            },
          },
          items: [
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/2ea2a029-3166-464e-bb39-ff7d6d1971db_1938116a-0b06-4321-93f3-d0694ab42c28_3T1A8190.jpg?auto=compress%2Cformat&rect=0%2C0%2C5540%2C3695&w=1000&h=667',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFqJyyqmIT/xAAaEAEAAgMBAAAAAAAAAAAAAAABABECAxIE/9oACAEBAAEFAncC+gruzc3nOmf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABoQAAICAwAAAAAAAAAAAAAAAAABECERQYH/2gAIAQEABj8CxsdOizg4/8QAGhABAQADAQEAAAAAAAAAAAAAAQARMVEhQf/aAAgBAQABPyH6fhnBu5GQAQikgM2heQAet//aAAwDAQACAAMAAAAQC/8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QtT//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QDYX/xAAaEAEBAQEAAwAAAAAAAAAAAAABEQAxUWGB/9oACAEBAAE/EL/QSsgl5jVNJTnX3gXwU8axWoO6qQ0D8mAjgb//2Q==',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/2abaaa8e62761749878885909ad509f7/1b035/2ea2a029-3166-464e-bb39-ff7d6d1971db_1938116a-0b06-4321-93f3-d0694ab42c28_3T1A8190.jpg',
                          srcSet:
                            '/static/2abaaa8e62761749878885909ad509f7/99f43/2ea2a029-3166-464e-bb39-ff7d6d1971db_1938116a-0b06-4321-93f3-d0694ab42c28_3T1A8190.jpg 250w,\n/static/2abaaa8e62761749878885909ad509f7/28380/2ea2a029-3166-464e-bb39-ff7d6d1971db_1938116a-0b06-4321-93f3-d0694ab42c28_3T1A8190.jpg 500w,\n/static/2abaaa8e62761749878885909ad509f7/1b035/2ea2a029-3166-464e-bb39-ff7d6d1971db_1938116a-0b06-4321-93f3-d0694ab42c28_3T1A8190.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/2abaaa8e62761749878885909ad509f7/48571/2ea2a029-3166-464e-bb39-ff7d6d1971db_1938116a-0b06-4321-93f3-d0694ab42c28_3T1A8190.webp 250w,\n/static/2abaaa8e62761749878885909ad509f7/76f01/2ea2a029-3166-464e-bb39-ff7d6d1971db_1938116a-0b06-4321-93f3-d0694ab42c28_3T1A8190.webp 500w,\n/static/2abaaa8e62761749878885909ad509f7/b7ef5/2ea2a029-3166-464e-bb39-ff7d6d1971db_1938116a-0b06-4321-93f3-d0694ab42c28_3T1A8190.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 667,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/5c5d9cf0-f363-4a75-8a9d-236b9adf9430_982bba4d-87bc-4579-86e8-ae958fcd1ca3_8A1724A4-2138-4620-A238-D4EA51075EE2.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1536%2C2049&w=1000&h=1334',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAHPHRhvDwOeuqjRYM4z/8QAHBAAAgIDAQEAAAAAAAAAAAAAAQIAEhAREwMy/9oACAEBAAEFAupnVjE3Rqk1nmoon1vSWGGx/8QAFxEBAAMAAAAAAAAAAAAAAAAAEAERIf/aAAgBAwEBPwGStP/EABgRAAIDAAAAAAAAAAAAAAAAABARARJB/9oACAECAQE/AU4FsH//xAAYEAEBAAMAAAAAAAAAAAAAAAABEAAgMf/aAAgBAQAGPwLkMAhA1//EABwQAQADAQEAAwAAAAAAAAAAAAEAESFBMRBhcf/aAAgBAQABPyGsubWUTnrmwOn3krtU0+vYnMcg6LJ1FukCNU/JVeTw/H//2gAMAwEAAgADAAAAEFMWs//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBA4NraNDBf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxAWmSGwU4kv/8QAHRABAAIBBQEAAAAAAAAAAAAAAQARITFBUWFxsf/aAAgBAQABPxAEAB5ZUAbbLGnFZIrW73YEam3cvjVhWEriHz1KDqEuHS9xKIWBBZPdYdGuG0QGJo6iVlyGIb+z/9k=',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/d650bf14ce1fb3aeb0b2638f84f2b48b/8adf2/5c5d9cf0-f363-4a75-8a9d-236b9adf9430_982bba4d-87bc-4579-86e8-ae958fcd1ca3_8A1724A4-2138-4620-A238-D4EA51075EE2.jpg',
                          srcSet:
                            '/static/d650bf14ce1fb3aeb0b2638f84f2b48b/38864/5c5d9cf0-f363-4a75-8a9d-236b9adf9430_982bba4d-87bc-4579-86e8-ae958fcd1ca3_8A1724A4-2138-4620-A238-D4EA51075EE2.jpg 250w,\n/static/d650bf14ce1fb3aeb0b2638f84f2b48b/27697/5c5d9cf0-f363-4a75-8a9d-236b9adf9430_982bba4d-87bc-4579-86e8-ae958fcd1ca3_8A1724A4-2138-4620-A238-D4EA51075EE2.jpg 500w,\n/static/d650bf14ce1fb3aeb0b2638f84f2b48b/8adf2/5c5d9cf0-f363-4a75-8a9d-236b9adf9430_982bba4d-87bc-4579-86e8-ae958fcd1ca3_8A1724A4-2138-4620-A238-D4EA51075EE2.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/d650bf14ce1fb3aeb0b2638f84f2b48b/db940/5c5d9cf0-f363-4a75-8a9d-236b9adf9430_982bba4d-87bc-4579-86e8-ae958fcd1ca3_8A1724A4-2138-4620-A238-D4EA51075EE2.webp 250w,\n/static/d650bf14ce1fb3aeb0b2638f84f2b48b/d7111/5c5d9cf0-f363-4a75-8a9d-236b9adf9430_982bba4d-87bc-4579-86e8-ae958fcd1ca3_8A1724A4-2138-4620-A238-D4EA51075EE2.webp 500w,\n/static/d650bf14ce1fb3aeb0b2638f84f2b48b/8aaae/5c5d9cf0-f363-4a75-8a9d-236b9adf9430_982bba4d-87bc-4579-86e8-ae958fcd1ca3_8A1724A4-2138-4620-A238-D4EA51075EE2.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 1334,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/b83e618b-e5f6-45b4-acd5-279033ded39b_a836b429-deb5-43a2-b102-a8a69b5386a7_HL0B0581.jpg?auto=compress%2Cformat&rect=0%2C0%2C3498%2C2333&w=1000&h=667',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAAD/9oADAMBAAIQAxAAAAFmMYTEpN//xAAaEAACAgMAAAAAAAAAAAAAAAABAgAxAxEi/9oACAEBAAEFAjfQG1gsrMrEP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAaEAABBQEAAAAAAAAAAAAAAAAAARARIUFR/9oACAEBAAY/AiV3hcq1N//EABwQAAICAgMAAAAAAAAAAAAAAAERACExUUGRof/aAAgBAQABPyE+kdEXDIQlSH2JcZkZVqIQPs//2gAMAwEAAgADAAAAEOTf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QV//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIf/aAAgBAgEBPxBQHLF//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFhQf/aAAgBAQABPxCptKao8lbjDB1M17Cq0K4lWwjn1lwgZh1T8YgVVX1P/9k=',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/ad607ab74f136733bc8b5db201c61394/1b035/b83e618b-e5f6-45b4-acd5-279033ded39b_a836b429-deb5-43a2-b102-a8a69b5386a7_HL0B0581.jpg',
                          srcSet:
                            '/static/ad607ab74f136733bc8b5db201c61394/99f43/b83e618b-e5f6-45b4-acd5-279033ded39b_a836b429-deb5-43a2-b102-a8a69b5386a7_HL0B0581.jpg 250w,\n/static/ad607ab74f136733bc8b5db201c61394/28380/b83e618b-e5f6-45b4-acd5-279033ded39b_a836b429-deb5-43a2-b102-a8a69b5386a7_HL0B0581.jpg 500w,\n/static/ad607ab74f136733bc8b5db201c61394/1b035/b83e618b-e5f6-45b4-acd5-279033ded39b_a836b429-deb5-43a2-b102-a8a69b5386a7_HL0B0581.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/ad607ab74f136733bc8b5db201c61394/48571/b83e618b-e5f6-45b4-acd5-279033ded39b_a836b429-deb5-43a2-b102-a8a69b5386a7_HL0B0581.webp 250w,\n/static/ad607ab74f136733bc8b5db201c61394/76f01/b83e618b-e5f6-45b4-acd5-279033ded39b_a836b429-deb5-43a2-b102-a8a69b5386a7_HL0B0581.webp 500w,\n/static/ad607ab74f136733bc8b5db201c61394/b7ef5/b83e618b-e5f6-45b4-acd5-279033ded39b_a836b429-deb5-43a2-b102-a8a69b5386a7_HL0B0581.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 667,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/4ee35b2e-55da-4722-b0c6-d2116ca9c0a9_84bbc5a9-8f02-4539-8195-211c190bc240_IMG_1056.jpeg?auto=compress%2Cformat&rect=0%2C0%2C3024%2C4031&w=1000&h=1333',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBBAUC/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB5dSsy9EzgJVroHRGE6f/xAAdEAACAwACAwAAAAAAAAAAAAABAgADEQQTFCEx/9oACAEBAAEFAmfZ2eg2imxa4eTVFuOOq546KYI/0z//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8BRiP/xAAWEQEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQIBAT8BNb//xAAeEAACAQMFAAAAAAAAAAAAAAAAARICMWERICMyUf/aAAgBAQAGPwJPBSanIngsdSMZPwtt/8QAHRAAAgICAwEAAAAAAAAAAAAAAREAMSFREEFhgf/aAAgBAQABPyEIsuBlWXFtHMeZ46ETKN9RexP1QhmvoNwsM4R5KcFp/9oADAMBAAIAAwAAABDY7nL/xAAYEQADAQEAAAAAAAAAAAAAAAAAESEBEP/aAAgBAwEBPxBxDFbx/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQIBAT8Q6pRqY//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExQVFhkeH/2gAIAQEAAT8QUw0c+zNWgAizrLQUKnjAK1CgQOv3K1tWjZ94yirbEWmDRqcG+wLkc8kWzFvA9GElm7MJZM5c/9k=',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/0825b77bec01e2610a50029d8f7e7bd5/f0d5a/4ee35b2e-55da-4722-b0c6-d2116ca9c0a9_84bbc5a9-8f02-4539-8195-211c190bc240_IMG_1056.jpg',
                          srcSet:
                            '/static/0825b77bec01e2610a50029d8f7e7bd5/562b9/4ee35b2e-55da-4722-b0c6-d2116ca9c0a9_84bbc5a9-8f02-4539-8195-211c190bc240_IMG_1056.jpg 250w,\n/static/0825b77bec01e2610a50029d8f7e7bd5/27697/4ee35b2e-55da-4722-b0c6-d2116ca9c0a9_84bbc5a9-8f02-4539-8195-211c190bc240_IMG_1056.jpg 500w,\n/static/0825b77bec01e2610a50029d8f7e7bd5/f0d5a/4ee35b2e-55da-4722-b0c6-d2116ca9c0a9_84bbc5a9-8f02-4539-8195-211c190bc240_IMG_1056.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/0825b77bec01e2610a50029d8f7e7bd5/31860/4ee35b2e-55da-4722-b0c6-d2116ca9c0a9_84bbc5a9-8f02-4539-8195-211c190bc240_IMG_1056.webp 250w,\n/static/0825b77bec01e2610a50029d8f7e7bd5/d7111/4ee35b2e-55da-4722-b0c6-d2116ca9c0a9_84bbc5a9-8f02-4539-8195-211c190bc240_IMG_1056.webp 500w,\n/static/0825b77bec01e2610a50029d8f7e7bd5/44a43/4ee35b2e-55da-4722-b0c6-d2116ca9c0a9_84bbc5a9-8f02-4539-8195-211c190bc240_IMG_1056.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 1333,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/81427eca-c5c1-4d7b-96ed-c100b3959e5a_c5221cc6-26a1-4767-98ef-8d5d1439cfe7_HL0B0767.jpg?auto=compress%2Cformat&rect=0%2C0%2C3498%2C2333&w=1000&h=667',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABiyVo4DB//8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxIRE//aAAgBAQABBQJV1HrKgg99GENzkan/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAABEBEAIf/aAAgBAQAGPwJ8OhNX/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAEhEWExUf/aAAgBAQABPyHmqhpMgoyYvUrPgz53UakUP//aAAwDAQACAAMAAAAQm/8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQADAQEAAAAAAAAAAAAAAREAITFBkf/aAAgBAQABPxDTBFHbsy4KuOWYpj4+C0JleL0C/ZmsKwBTzP/Z',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/2bbf9868cbf9ef6ac51dea1120faac17/1b035/81427eca-c5c1-4d7b-96ed-c100b3959e5a_c5221cc6-26a1-4767-98ef-8d5d1439cfe7_HL0B0767.jpg',
                          srcSet:
                            '/static/2bbf9868cbf9ef6ac51dea1120faac17/99f43/81427eca-c5c1-4d7b-96ed-c100b3959e5a_c5221cc6-26a1-4767-98ef-8d5d1439cfe7_HL0B0767.jpg 250w,\n/static/2bbf9868cbf9ef6ac51dea1120faac17/28380/81427eca-c5c1-4d7b-96ed-c100b3959e5a_c5221cc6-26a1-4767-98ef-8d5d1439cfe7_HL0B0767.jpg 500w,\n/static/2bbf9868cbf9ef6ac51dea1120faac17/1b035/81427eca-c5c1-4d7b-96ed-c100b3959e5a_c5221cc6-26a1-4767-98ef-8d5d1439cfe7_HL0B0767.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/2bbf9868cbf9ef6ac51dea1120faac17/48571/81427eca-c5c1-4d7b-96ed-c100b3959e5a_c5221cc6-26a1-4767-98ef-8d5d1439cfe7_HL0B0767.webp 250w,\n/static/2bbf9868cbf9ef6ac51dea1120faac17/76f01/81427eca-c5c1-4d7b-96ed-c100b3959e5a_c5221cc6-26a1-4767-98ef-8d5d1439cfe7_HL0B0767.webp 500w,\n/static/2bbf9868cbf9ef6ac51dea1120faac17/b7ef5/81427eca-c5c1-4d7b-96ed-c100b3959e5a_c5221cc6-26a1-4767-98ef-8d5d1439cfe7_HL0B0767.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 667,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/6e4a0ca8-a8f9-4fbe-82e3-2c3af76de94c_5d4f84c3-d7e4-4965-b692-25ca696a31c0_HL0B0667.jpg?auto=compress%2Cformat&rect=0%2C0%2C2333%2C3500&w=1000&h=1500',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAe1GZ4uArNZomEGK/8QAGxAAAwACAwAAAAAAAAAAAAAAAAECEzEQESH/2gAIAQEAAQUCOxelt5qJ1c1nsWuf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACECAh/9oACAEBAAY/AtqRyqHkef/EAB4QAQACAgEFAAAAAAAAAAAAAAEAERAhQTFRYcHw/9oACAEBAAE/IbCIIqXU3JpnXvx6wsvnN7QP3xiNkqBRP//aAAwDAQACAAMAAAAQuwa8/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8QhT//xAAWEQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8QGrgS5//EAB0QAQEBAAICAwAAAAAAAAAAAAERACGxQYExUXH/2gAIAQEAAT8QVRY/WcSWeVhhojlMIPwlJqnQVx6YcP17zBAIXbBWFI9MYffeANPGm3BLf//Z',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/5bbdd76cf7e13f934a72c98afb8f8c47/0cb03/6e4a0ca8-a8f9-4fbe-82e3-2c3af76de94c_5d4f84c3-d7e4-4965-b692-25ca696a31c0_HL0B0667.jpg',
                          srcSet:
                            '/static/5bbdd76cf7e13f934a72c98afb8f8c47/2579c/6e4a0ca8-a8f9-4fbe-82e3-2c3af76de94c_5d4f84c3-d7e4-4965-b692-25ca696a31c0_HL0B0667.jpg 250w,\n/static/5bbdd76cf7e13f934a72c98afb8f8c47/2ff65/6e4a0ca8-a8f9-4fbe-82e3-2c3af76de94c_5d4f84c3-d7e4-4965-b692-25ca696a31c0_HL0B0667.jpg 500w,\n/static/5bbdd76cf7e13f934a72c98afb8f8c47/0cb03/6e4a0ca8-a8f9-4fbe-82e3-2c3af76de94c_5d4f84c3-d7e4-4965-b692-25ca696a31c0_HL0B0667.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5bbdd76cf7e13f934a72c98afb8f8c47/1c893/6e4a0ca8-a8f9-4fbe-82e3-2c3af76de94c_5d4f84c3-d7e4-4965-b692-25ca696a31c0_HL0B0667.webp 250w,\n/static/5bbdd76cf7e13f934a72c98afb8f8c47/316df/6e4a0ca8-a8f9-4fbe-82e3-2c3af76de94c_5d4f84c3-d7e4-4965-b692-25ca696a31c0_HL0B0667.webp 500w,\n/static/5bbdd76cf7e13f934a72c98afb8f8c47/b3e07/6e4a0ca8-a8f9-4fbe-82e3-2c3af76de94c_5d4f84c3-d7e4-4965-b692-25ca696a31c0_HL0B0667.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 1500,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/55e1e5b8-733b-474b-ad37-dff2f68d133f_01def25b-eb39-48ba-8bcf-82bc9cb4b2f5_3T1A8353.jpg?auto=compress%2Cformat&rect=0%2C0%2C5534%2C3691&w=1000&h=667',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABcuLvNvkcP//EABsQAAEFAQEAAAAAAAAAAAAAAAEAAgMEEhQx/9oACAEBAAEFApLAjJs7PUxp9Wyg9f/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERMRIhMmH/2gAIAQEABj8CixRRjUGxeHKP/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFBYXGR/9oACAEBAAE/IeIlZi1KGNmvmLSNK4i27e58CpaQ/9oADAMBAAIAAwAAABCY/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxCIf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EKp//8QAGxABAQACAwEAAAAAAAAAAAAAAREAMSFRcWH/2gAIAQEAAT8QIklHWO2/MInKUtC68x5KaNPSZ2R3yyLEAABxMEVRduf/2Q==',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/ca29ecd02c84bbc1dbd7098f00ccbdc1/1b035/55e1e5b8-733b-474b-ad37-dff2f68d133f_01def25b-eb39-48ba-8bcf-82bc9cb4b2f5_3T1A8353.jpg',
                          srcSet:
                            '/static/ca29ecd02c84bbc1dbd7098f00ccbdc1/99f43/55e1e5b8-733b-474b-ad37-dff2f68d133f_01def25b-eb39-48ba-8bcf-82bc9cb4b2f5_3T1A8353.jpg 250w,\n/static/ca29ecd02c84bbc1dbd7098f00ccbdc1/28380/55e1e5b8-733b-474b-ad37-dff2f68d133f_01def25b-eb39-48ba-8bcf-82bc9cb4b2f5_3T1A8353.jpg 500w,\n/static/ca29ecd02c84bbc1dbd7098f00ccbdc1/1b035/55e1e5b8-733b-474b-ad37-dff2f68d133f_01def25b-eb39-48ba-8bcf-82bc9cb4b2f5_3T1A8353.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/ca29ecd02c84bbc1dbd7098f00ccbdc1/48571/55e1e5b8-733b-474b-ad37-dff2f68d133f_01def25b-eb39-48ba-8bcf-82bc9cb4b2f5_3T1A8353.webp 250w,\n/static/ca29ecd02c84bbc1dbd7098f00ccbdc1/76f01/55e1e5b8-733b-474b-ad37-dff2f68d133f_01def25b-eb39-48ba-8bcf-82bc9cb4b2f5_3T1A8353.webp 500w,\n/static/ca29ecd02c84bbc1dbd7098f00ccbdc1/b7ef5/55e1e5b8-733b-474b-ad37-dff2f68d133f_01def25b-eb39-48ba-8bcf-82bc9cb4b2f5_3T1A8353.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 667,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/b6110e68-5364-4da3-8772-b3f4ec062132_4c64c6ef-b306-4763-ad61-a3949815a3ab_IMG_0046.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1536%2C2047&w=1000&h=1333',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQCAwUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAFmNVeVggJ3M00ZbIEf/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAEQMxECIj/9oACAEBAAEFAhDuXSplLGNper8MPUz/xAAWEQEBAQAAAAAAAAAAAAAAAAAQAUH/2gAIAQMBAT8Bwp//xAAVEQEBAAAAAAAAAAAAAAAAAAAQAf/aAAgBAgEBPwEh/8QAGxAAAgEFAAAAAAAAAAAAAAAAARAAAhESgZH/2gAIAQEABj8CZqlisevcC//EAB0QAAMAAgIDAAAAAAAAAAAAAAABESExEEFRcYH/2gAIAQEAAT8h70r1KW6zFogVLFU4X7B2u9OMvkeWwpluf//aAAwDAQACAAMAAAAQr8m+/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARUf/aAAgBAwEBPxAyNR0r/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEQITH/2gAIAQIBAT8QtiMR/8QAHxABAAMBAAEFAQAAAAAAAAAAAQARITFxQWGBobHB/9oACAEBAAE/EKaB2oLB4MqKvEi9DVZqXcXkYreP1BE9rr8IDFH+ZB1o+WorbqofECUse+9Tnhz1J//Z',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/fade9082b00483d9107b62435c2a58fb/f0d5a/b6110e68-5364-4da3-8772-b3f4ec062132_4c64c6ef-b306-4763-ad61-a3949815a3ab_IMG_0046.jpg',
                          srcSet:
                            '/static/fade9082b00483d9107b62435c2a58fb/562b9/b6110e68-5364-4da3-8772-b3f4ec062132_4c64c6ef-b306-4763-ad61-a3949815a3ab_IMG_0046.jpg 250w,\n/static/fade9082b00483d9107b62435c2a58fb/27697/b6110e68-5364-4da3-8772-b3f4ec062132_4c64c6ef-b306-4763-ad61-a3949815a3ab_IMG_0046.jpg 500w,\n/static/fade9082b00483d9107b62435c2a58fb/f0d5a/b6110e68-5364-4da3-8772-b3f4ec062132_4c64c6ef-b306-4763-ad61-a3949815a3ab_IMG_0046.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/fade9082b00483d9107b62435c2a58fb/31860/b6110e68-5364-4da3-8772-b3f4ec062132_4c64c6ef-b306-4763-ad61-a3949815a3ab_IMG_0046.webp 250w,\n/static/fade9082b00483d9107b62435c2a58fb/d7111/b6110e68-5364-4da3-8772-b3f4ec062132_4c64c6ef-b306-4763-ad61-a3949815a3ab_IMG_0046.webp 500w,\n/static/fade9082b00483d9107b62435c2a58fb/44a43/b6110e68-5364-4da3-8772-b3f4ec062132_4c64c6ef-b306-4763-ad61-a3949815a3ab_IMG_0046.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 1333,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/59eb592d-d8f4-486c-b6d6-7f5efaeca314_9680df47-22d5-44ff-8bf7-bb6fbba43ae0_IMG_0802.jpeg?auto=compress%2Cformat&rect=0%2C0%2C3024%2C4031&w=1000&h=1333',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAwL/2gAMAwEAAhADEAAAAbyZrToMBOFVzlpxQP/EABkQAAMBAQEAAAAAAAAAAAAAAAABERICEP/aAAgBAQABBQL3SNI0ixUtOTpwsX//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BI//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEr/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEgYf/aAAgBAQAGPwKtlH//xAAeEAEAAgEEAwAAAAAAAAAAAAABABExEEFRYXGBof/aAAgBAQABPyESe9O6HJO6YplvFDaX4ZbB+TZGRTzBUNyf/9oADAMBAAIAAwAAABBAwLD/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAwEBPxAh/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAECAQE/EHDN/8QAHhABAAICAgMBAAAAAAAAAAAAAQAhETFBYVFxgZH/2gAIAQEAAT8QQUwwNwwS8PkUVOLi+4skzo8Dvvqb4PGz5A2cBq1LOathfqUzWPxxMhQsa2z/2Q==',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/f0312e9ba3cbc24fa9ada602a369fb18/f0d5a/59eb592d-d8f4-486c-b6d6-7f5efaeca314_9680df47-22d5-44ff-8bf7-bb6fbba43ae0_IMG_0802.jpg',
                          srcSet:
                            '/static/f0312e9ba3cbc24fa9ada602a369fb18/562b9/59eb592d-d8f4-486c-b6d6-7f5efaeca314_9680df47-22d5-44ff-8bf7-bb6fbba43ae0_IMG_0802.jpg 250w,\n/static/f0312e9ba3cbc24fa9ada602a369fb18/27697/59eb592d-d8f4-486c-b6d6-7f5efaeca314_9680df47-22d5-44ff-8bf7-bb6fbba43ae0_IMG_0802.jpg 500w,\n/static/f0312e9ba3cbc24fa9ada602a369fb18/f0d5a/59eb592d-d8f4-486c-b6d6-7f5efaeca314_9680df47-22d5-44ff-8bf7-bb6fbba43ae0_IMG_0802.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/f0312e9ba3cbc24fa9ada602a369fb18/31860/59eb592d-d8f4-486c-b6d6-7f5efaeca314_9680df47-22d5-44ff-8bf7-bb6fbba43ae0_IMG_0802.webp 250w,\n/static/f0312e9ba3cbc24fa9ada602a369fb18/d7111/59eb592d-d8f4-486c-b6d6-7f5efaeca314_9680df47-22d5-44ff-8bf7-bb6fbba43ae0_IMG_0802.webp 500w,\n/static/f0312e9ba3cbc24fa9ada602a369fb18/44a43/59eb592d-d8f4-486c-b6d6-7f5efaeca314_9680df47-22d5-44ff-8bf7-bb6fbba43ae0_IMG_0802.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 1333,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/c8ebadff-c430-42d6-a7df-30b579379e06_b234d960-56e4-4ed6-97dc-36d08443857c_HL0B1017.jpg?auto=compress%2Cformat&rect=0%2C0%2C3498%2C2333&w=1000&h=667',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABmy3S5wko/8QAGhAAAgMBAQAAAAAAAAAAAAAAAgMAARESIv/aAAgBAQABBQKsKGsTlPJcWHSsyjr1/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Bqv/EABkQAQACAwAAAAAAAAAAAAAAAAEAIRAREv/aAAgBAQAGPwKaZyWEbc//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUFRcf/aAAgBAQABPyFbDENPe4HeaFwjIW8RPZcAVT//2gAMAwEAAgADAAAAEK8v/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EJGP/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQIBAT8QpD//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhUYExYXGx/9oACAEBAAE/EAGEV9GVdISfzvPDrC2hx8wV45VsKMxbaEWvMydIO53n/9k=',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/7e4bf2ba13a5705dd5af37dddb52d86f/1b035/c8ebadff-c430-42d6-a7df-30b579379e06_b234d960-56e4-4ed6-97dc-36d08443857c_HL0B1017.jpg',
                          srcSet:
                            '/static/7e4bf2ba13a5705dd5af37dddb52d86f/99f43/c8ebadff-c430-42d6-a7df-30b579379e06_b234d960-56e4-4ed6-97dc-36d08443857c_HL0B1017.jpg 250w,\n/static/7e4bf2ba13a5705dd5af37dddb52d86f/28380/c8ebadff-c430-42d6-a7df-30b579379e06_b234d960-56e4-4ed6-97dc-36d08443857c_HL0B1017.jpg 500w,\n/static/7e4bf2ba13a5705dd5af37dddb52d86f/1b035/c8ebadff-c430-42d6-a7df-30b579379e06_b234d960-56e4-4ed6-97dc-36d08443857c_HL0B1017.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/7e4bf2ba13a5705dd5af37dddb52d86f/48571/c8ebadff-c430-42d6-a7df-30b579379e06_b234d960-56e4-4ed6-97dc-36d08443857c_HL0B1017.webp 250w,\n/static/7e4bf2ba13a5705dd5af37dddb52d86f/76f01/c8ebadff-c430-42d6-a7df-30b579379e06_b234d960-56e4-4ed6-97dc-36d08443857c_HL0B1017.webp 500w,\n/static/7e4bf2ba13a5705dd5af37dddb52d86f/b7ef5/c8ebadff-c430-42d6-a7df-30b579379e06_b234d960-56e4-4ed6-97dc-36d08443857c_HL0B1017.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 667,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/30dc0c13-b502-42b8-aa01-a2570dfb2121_eb866312-87d7-4c7e-a550-650cfa2a07f8_IMG_0058.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1537%2C2049&w=1000&h=1333',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQL/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAWZt6stg0rMTzKm+Rg//xAAcEAACAgIDAAAAAAAAAAAAAAABAgADEyERIjP/2gAIAQEAAQUCu8xlLS1umQAFN7aWKUcuplp4UElp/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHxAAAQMEAwEAAAAAAAAAAAAAAQAQEQIhQVESMWFx/9oACAEBAAY/Ajj1C9UfW6yqQNtx2UJhXVkJJb//xAAcEAADAQACAwAAAAAAAAAAAAAAAREhMUEQUXH/2gAIAQEAAT8hojMOrkN6+RbRfQeOsWGrUXhK0Ew/tg1qOjKM1vRVBvb8f//aAAwDAQACAAMAAAAQi/KO/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAQEf/aAAgBAwEBPxBuyZ//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/EEoQWf/EABwQAQEBAAIDAQAAAAAAAAAAAAERACFBMVFhcf/aAAgBAQABPxBIB4cAY3jOK8jSSl7z8wm/DQbnme/mJnkvVKLpGTvo9ZSBCVZfN/MKIpAqJZlpa8yOpC9lHJDsHI8XG//Z',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/5d52a60cc567a4e18320f96875dbf29a/f0d5a/30dc0c13-b502-42b8-aa01-a2570dfb2121_eb866312-87d7-4c7e-a550-650cfa2a07f8_IMG_0058.jpg',
                          srcSet:
                            '/static/5d52a60cc567a4e18320f96875dbf29a/562b9/30dc0c13-b502-42b8-aa01-a2570dfb2121_eb866312-87d7-4c7e-a550-650cfa2a07f8_IMG_0058.jpg 250w,\n/static/5d52a60cc567a4e18320f96875dbf29a/27697/30dc0c13-b502-42b8-aa01-a2570dfb2121_eb866312-87d7-4c7e-a550-650cfa2a07f8_IMG_0058.jpg 500w,\n/static/5d52a60cc567a4e18320f96875dbf29a/f0d5a/30dc0c13-b502-42b8-aa01-a2570dfb2121_eb866312-87d7-4c7e-a550-650cfa2a07f8_IMG_0058.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5d52a60cc567a4e18320f96875dbf29a/31860/30dc0c13-b502-42b8-aa01-a2570dfb2121_eb866312-87d7-4c7e-a550-650cfa2a07f8_IMG_0058.webp 250w,\n/static/5d52a60cc567a4e18320f96875dbf29a/d7111/30dc0c13-b502-42b8-aa01-a2570dfb2121_eb866312-87d7-4c7e-a550-650cfa2a07f8_IMG_0058.webp 500w,\n/static/5d52a60cc567a4e18320f96875dbf29a/44a43/30dc0c13-b502-42b8-aa01-a2570dfb2121_eb866312-87d7-4c7e-a550-650cfa2a07f8_IMG_0058.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 1333,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/dae32ecf-5d4e-4a77-b8a8-7ca3fbfd1d04_b460ebec-db68-479d-986d-098b3a0ef33b_3T1A8255.jpg?auto=compress%2Cformat&rect=0%2C0%2C5757%2C3840&w=1000&h=667',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAEzLSJciL//xAAbEAADAAIDAAAAAAAAAAAAAAABAgMABBESIf/aAAgBAQABBQKVe9dg8PPbCqgApQeZ/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/AU2f/8QAFxEAAwEAAAAAAAAAAAAAAAAAAQIQEf/aAAgBAgEBPwENk//EABsQAAICAwEAAAAAAAAAAAAAAAARAQIQEkFR/9oACAEBAAY/Al5AxajjsF7Y/8QAGxABAAICAwAAAAAAAAAAAAAAAQARITEQUYH/2gAIAQEAAT8hvaodsUK2QWm+wkWYbxlNcf/aAAwDAQACAAMAAAAQ8z//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/EBHYMML/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EKMG7p//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhQcFRYaGx/9oACAEBAAE/EFoAMaLa/sxAQkvs3PuP2zkJ1iaS53ou+sk5IDwOOsHWf//Z',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/7764bada3e5f006677f7d6787c2622ab/1b035/dae32ecf-5d4e-4a77-b8a8-7ca3fbfd1d04_b460ebec-db68-479d-986d-098b3a0ef33b_3T1A8255.jpg',
                          srcSet:
                            '/static/7764bada3e5f006677f7d6787c2622ab/99f43/dae32ecf-5d4e-4a77-b8a8-7ca3fbfd1d04_b460ebec-db68-479d-986d-098b3a0ef33b_3T1A8255.jpg 250w,\n/static/7764bada3e5f006677f7d6787c2622ab/28380/dae32ecf-5d4e-4a77-b8a8-7ca3fbfd1d04_b460ebec-db68-479d-986d-098b3a0ef33b_3T1A8255.jpg 500w,\n/static/7764bada3e5f006677f7d6787c2622ab/1b035/dae32ecf-5d4e-4a77-b8a8-7ca3fbfd1d04_b460ebec-db68-479d-986d-098b3a0ef33b_3T1A8255.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/7764bada3e5f006677f7d6787c2622ab/48571/dae32ecf-5d4e-4a77-b8a8-7ca3fbfd1d04_b460ebec-db68-479d-986d-098b3a0ef33b_3T1A8255.webp 250w,\n/static/7764bada3e5f006677f7d6787c2622ab/76f01/dae32ecf-5d4e-4a77-b8a8-7ca3fbfd1d04_b460ebec-db68-479d-986d-098b3a0ef33b_3T1A8255.webp 500w,\n/static/7764bada3e5f006677f7d6787c2622ab/b7ef5/dae32ecf-5d4e-4a77-b8a8-7ca3fbfd1d04_b460ebec-db68-479d-986d-098b3a0ef33b_3T1A8255.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 667,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/6306067d-8e99-47cc-8197-6012c88e746e_09a205b7-a9ca-4885-8e18-a53129507dbc_IMG_6937.jpeg?auto=compress%2Cformat&rect=0%2C0%2C3024%2C4031&w=1000&h=1333',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAbVmxNsKgLiF7uaima//xAAgEAABAgUFAAAAAAAAAAAAAAABAgQAAxEiMRITITIz/9oACAEBAAEFAjBxtVDikSuCMPU3NwdKDZOFVnzR0//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/AYk//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/AZUCv//EABwQAAICAgMAAAAAAAAAAAAAAAABAhEDECExcf/aAAgBAQAGPwLV2xKRx1YhGRMQiQvD/8QAHBABAQEBAQADAQAAAAAAAAAAAREAMSEQQVGB/9oACAEBAAE/IX6Y2HEEVLk2CW9mtBscfTifyyPL180J+wjv0+YKCzEGOz4L/9oADAMBAAIAAwAAABCbHg//xAAYEQEBAQEBAAAAAAAAAAAAAAABACERMf/aAAgBAwEBPxBz1gumwF//xAAYEQEBAAMAAAAAAAAAAAAAAAABABExQf/aAAgBAgEBPxAWhK3KVzf/xAAfEAEBAAICAgMBAAAAAAAAAAABEQAhMUFxgVFhobH/2gAIAQEAAT8Q5iPvAegWs51vDKMQENXFiHYNbzaagttOx9/mEI6HOaIBJPiTAIrkH1APGEC7BG85CqFL03BagaJzZhF34fzP/9k=',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/5f5f4c7ae9cb3aea6e49fead75cb9a4d/f0d5a/6306067d-8e99-47cc-8197-6012c88e746e_09a205b7-a9ca-4885-8e18-a53129507dbc_IMG_6937.jpg',
                          srcSet:
                            '/static/5f5f4c7ae9cb3aea6e49fead75cb9a4d/562b9/6306067d-8e99-47cc-8197-6012c88e746e_09a205b7-a9ca-4885-8e18-a53129507dbc_IMG_6937.jpg 250w,\n/static/5f5f4c7ae9cb3aea6e49fead75cb9a4d/27697/6306067d-8e99-47cc-8197-6012c88e746e_09a205b7-a9ca-4885-8e18-a53129507dbc_IMG_6937.jpg 500w,\n/static/5f5f4c7ae9cb3aea6e49fead75cb9a4d/f0d5a/6306067d-8e99-47cc-8197-6012c88e746e_09a205b7-a9ca-4885-8e18-a53129507dbc_IMG_6937.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5f5f4c7ae9cb3aea6e49fead75cb9a4d/31860/6306067d-8e99-47cc-8197-6012c88e746e_09a205b7-a9ca-4885-8e18-a53129507dbc_IMG_6937.webp 250w,\n/static/5f5f4c7ae9cb3aea6e49fead75cb9a4d/d7111/6306067d-8e99-47cc-8197-6012c88e746e_09a205b7-a9ca-4885-8e18-a53129507dbc_IMG_6937.webp 500w,\n/static/5f5f4c7ae9cb3aea6e49fead75cb9a4d/44a43/6306067d-8e99-47cc-8197-6012c88e746e_09a205b7-a9ca-4885-8e18-a53129507dbc_IMG_6937.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 1333,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/21067780-53ab-4566-b521-89245eef93d2_5ef698cb-f643-4f9d-98a8-90ef97b933ff_IMG_0011.jpeg?auto=compress%2Cformat&rect=0%2C0%2C2048%2C1536&w=1000&h=750',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAE/8QAFAEBAAAAAAAAAAAAAAAAAAAAA//aAAwDAQACEAMQAAABzBFhoxJ//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEREBL/2gAIAQEAAQUCKmWZDhn/xAAYEQACAwAAAAAAAAAAAAAAAAAAAREiMf/aAAgBAwEBPwG0Cw//xAAZEQABBQAAAAAAAAAAAAAAAAAAAhEhIjH/2gAIAQIBAT8Bo4rYP//EABoQAAICAwAAAAAAAAAAAAAAAAARITIBEKH/2gAIAQEABj8CnLIYipXuv//EABwQAAEEAwEAAAAAAAAAAAAAAAEAESFRMUFhcf/aAAgBAQABPyF2G7Sw5rJW0X8RDnCOozsjk3a//9oADAMBAAIAAwAAABDb/wD/xAAYEQEBAQEBAAAAAAAAAAAAAAABEQAhMf/aAAgBAwEBPxAOytzYvu//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhQf/aAAgBAgEBPxBdmIC+L//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQYGR4f/aAAgBAQABPxBKKjgsJh6wiZba52uvGKiDAaeQ0kcp8ZSkA2irn//Z',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/fe0d071172964f98811e7976d2126ebf/de105/21067780-53ab-4566-b521-89245eef93d2_5ef698cb-f643-4f9d-98a8-90ef97b933ff_IMG_0011.jpg',
                          srcSet:
                            '/static/fe0d071172964f98811e7976d2126ebf/7ac0a/21067780-53ab-4566-b521-89245eef93d2_5ef698cb-f643-4f9d-98a8-90ef97b933ff_IMG_0011.jpg 250w,\n/static/fe0d071172964f98811e7976d2126ebf/51a07/21067780-53ab-4566-b521-89245eef93d2_5ef698cb-f643-4f9d-98a8-90ef97b933ff_IMG_0011.jpg 500w,\n/static/fe0d071172964f98811e7976d2126ebf/de105/21067780-53ab-4566-b521-89245eef93d2_5ef698cb-f643-4f9d-98a8-90ef97b933ff_IMG_0011.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/fe0d071172964f98811e7976d2126ebf/6ef21/21067780-53ab-4566-b521-89245eef93d2_5ef698cb-f643-4f9d-98a8-90ef97b933ff_IMG_0011.webp 250w,\n/static/fe0d071172964f98811e7976d2126ebf/12849/21067780-53ab-4566-b521-89245eef93d2_5ef698cb-f643-4f9d-98a8-90ef97b933ff_IMG_0011.webp 500w,\n/static/fe0d071172964f98811e7976d2126ebf/85e35/21067780-53ab-4566-b521-89245eef93d2_5ef698cb-f643-4f9d-98a8-90ef97b933ff_IMG_0011.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 750,
                    },
                  },
                },
              },
            },
            {
              image: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/a6c84bd6-2ead-4672-9b53-ce4d4fc7c678_29d97ba8-5479-40d5-ae4a-72611c2c0e04_IMG_0073.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1536%2C2087&w=1000&h=1359',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAMEAgEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAF6tefm3iRMz2IqQ4H/xAAdEAABBAIDAAAAAAAAAAAAAAABAAIDEQQSISJB/9oACAEBAAEFAnMpcrYhS2Yhuwmk6XvIQ4KEC84Bp8//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BcP/EAB0QAAIBBAMAAAAAAAAAAAAAAAABMQIQESEjMnH/2gAIAQEABj8Ck7EjVMnIyTG5wK1PhQ1rNv/EAB0QAAMAAgMBAQAAAAAAAAAAAAABESExQWFxgZH/2gAIAQEAAT8h4E/TFxN+ixnRyh6jFuZPaO86CmoGfc7K0lG4OUSff0QTVJwWh//aAAwDAQACAAMAAAAQtD/+/8QAGBEBAAMBAAAAAAAAAAAAAAAAAAERIUH/2gAIAQMBAT8QmVuWx//EABgRAAIDAAAAAAAAAAAAAAAAAAEQESFB/9oACAECAQE/EAKWwn//xAAdEAEBAQEBAAIDAAAAAAAAAAABESEAMUFRYXHR/9oACAEBAAE/EH068l522iffK61aN5x8jUn0+esrUg7e51SDfw4vj0gCwCZfuvSBaqNh9PTGRTfO1XNgp44RYEZoT+vY/T3/2Q==',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/5fcbbf9c84a824c6944229473b0780a2/bd25e/a6c84bd6-2ead-4672-9b53-ce4d4fc7c678_29d97ba8-5479-40d5-ae4a-72611c2c0e04_IMG_0073.jpg',
                          srcSet:
                            '/static/5fcbbf9c84a824c6944229473b0780a2/a5d60/a6c84bd6-2ead-4672-9b53-ce4d4fc7c678_29d97ba8-5479-40d5-ae4a-72611c2c0e04_IMG_0073.jpg 250w,\n/static/5fcbbf9c84a824c6944229473b0780a2/45159/a6c84bd6-2ead-4672-9b53-ce4d4fc7c678_29d97ba8-5479-40d5-ae4a-72611c2c0e04_IMG_0073.jpg 500w,\n/static/5fcbbf9c84a824c6944229473b0780a2/bd25e/a6c84bd6-2ead-4672-9b53-ce4d4fc7c678_29d97ba8-5479-40d5-ae4a-72611c2c0e04_IMG_0073.jpg 1000w',
                          sizes: '(min-width: 1000px) 1000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5fcbbf9c84a824c6944229473b0780a2/84fe0/a6c84bd6-2ead-4672-9b53-ce4d4fc7c678_29d97ba8-5479-40d5-ae4a-72611c2c0e04_IMG_0073.webp 250w,\n/static/5fcbbf9c84a824c6944229473b0780a2/fd824/a6c84bd6-2ead-4672-9b53-ce4d4fc7c678_29d97ba8-5479-40d5-ae4a-72611c2c0e04_IMG_0073.webp 500w,\n/static/5fcbbf9c84a824c6944229473b0780a2/d450f/a6c84bd6-2ead-4672-9b53-ce4d4fc7c678_29d97ba8-5479-40d5-ae4a-72611c2c0e04_IMG_0073.webp 1000w',
                            type: 'image/webp',
                            sizes: '(min-width: 1000px) 1000px, 100vw',
                          },
                        ],
                      },
                      width: 1000,
                      height: 1359,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
