const data = {
  name: 'MultiStepForm',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
          },
        },
      },
    },
  ],
};

export default data;
