// extracted by mini-css-extract-plugin
export const container = "fable-module--container--MZ9nx";
export const sidebar = "fable-module--sidebar--2bqgE";
export const sidebarLogo = "fable-module--sidebarLogo--2DNQo";
export const sidebarItems = "fable-module--sidebarItems--3pAxf";
export const sidebarItem = "fable-module--sidebarItem--3rOCg";
export const sidebarLink = "fable-module--sidebarLink--18rCO";
export const sidebarLinkActive = "fable-module--sidebarLinkActive--859fJ";
export const sidebarSublink = "fable-module--sidebarSublink--26ieS";
export const sidebarSublinkActive = "fable-module--sidebarSublinkActive--uMdEo";
export const sidebarArrow = "fable-module--sidebarArrow--1qv3n";
export const sidebarArrowActive = "fable-module--sidebarArrowActive--3aE-s";
export const content = "fable-module--content--NewIs";