const data = {
  name: 'ContactForm',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            gif: {
              url:
                'https://images.prismic.io/woolly-mammoth-v2/b6035be7-66be-48ce-a900-7045cf678f99_beers.gif?auto=compress,format',
            },
            content: {
              text: '*If you pay your invoices, answer our calls and are not opposed to Friday beers. ',
              html:
                '<p>*If you pay your invoices, answer our calls and are not opposed to <strong>Friday beers. </strong></p>',
            },
            cta_link: {
              url: '/contact-us/',
            },
            cta_text: {
              text: 'I pay',
              html: '<p>I pay</p>',
            },
            icon: 'Howdy Man',
            tagline: {
              text: 'Hey buddy',
              html: '<p>Hey buddy</p>',
            },
            title: {
              html: '<p>Convinced? We want to work with you too!*</p>',
              text: 'Convinced? We want to work with you too!*',
            },
          },
        },
      },
    },
  ],
};

export default data;
