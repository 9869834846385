const data = {
  name: 'LinksColumns',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            tagline: {
              text: '– Design',
              html: '<p>– Design</p>',
            },
            title: {
              html: '<p>Highly functioning &amp; intuitive design driven by user insights</p>',
              text: 'Highly functioning & intuitive design driven by user insights',
            },
            dark_mode: true,
          },
          items: [
            {
              link_text: {
                html: '<p>UX Design</p>',
                text: 'UX Design',
              },
              link_url: {
                url: '',
              },
            },
            {
              link_text: {
                html: '<p>UX Research</p>',
                text: 'UX Research',
              },
              link_url: {
                url: '',
              },
            },
            {
              link_text: {
                html: '<p>Web Design</p>',
                text: 'Web Design',
              },
              link_url: {
                url: '',
              },
            },
            {
              link_text: {
                html: '<p>Application Design</p>',
                text: 'Application Design',
              },
              link_url: {
                url: '',
              },
            },
            {
              link_text: {
                html: '<p>Brand Identity</p>',
                text: 'Brand Identity',
              },
              link_url: {
                url: '',
              },
            },
            {
              link_text: {
                html: '<p>Visual Design</p>',
                text: 'Visual Design',
              },
              link_url: {
                url: '',
              },
            },
            {
              link_text: {
                html: '<p>Prototyping and Testing</p>',
                text: 'Prototyping and Testing',
              },
              link_url: {
                url: '',
              },
            },
            {
              link_text: {
                html: '<p>User Interface Design</p>',
                text: 'User Interface Design',
              },
              link_url: {
                url: '',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
