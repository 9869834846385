const data = {
  name: 'Usps',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            cta_link: {
              url: '/services/',
            },
            cta_text: {
              text: 'Our services',
              html: '<p>Our services</p>',
            },
            title: {
              html: '',
              text: '',
            },
          },
          items: [
            {
              usp_description: {
                html:
                  '<p>We are a fun and highly driven herd that push for the best outcomes for our partner teams using 2021 technology</p>',
                text:
                  'We are a fun and highly driven herd that push for the best outcomes for our partner teams using 2021 technology',
              },
              usp_icon: 'Women Shaking Hands',
              usp_title: {
                html: '<p>Working with us</p>',
                text: 'Working with us',
              },
            },
            {
              usp_description: {
                html:
                  '<p>We live and breathe partnering with start-ups where we can challenge the status quo and drive growth</p>',
                text:
                  'We live and breathe partnering with start-ups where we can challenge the status quo and drive growth',
              },
              usp_icon: 'Man Holding Trophy',
              usp_title: {
                html: '<p>What drives us</p>',
                text: 'What drives us',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
