const data = {
  name: 'CardList',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            tagline: {
              html: '<p>Our Values</p>',
              text: 'Our Values',
            },
            title: {
              html: '<h1>We create world-class digital products, applications, and experiences.</h1>',
              text: 'We create world-class digital products, applications, and experiences.',
            },
          },
          items: [
            {
              card_content: {
                html:
                  '<p>We believe in embracing the latest developments in technology that push boundaries and deliver an unmatched user experience to drive growth for our partner teams (hopefully you). </p>',
                text:
                  'We believe in embracing the latest developments in technology that push boundaries and deliver an unmatched user experience to drive growth for our partner teams (hopefully you). ',
              },
              card_cta_link: {
                url: '/contact-us/',
              },
              card_cta_text: {
                html: '<p>Ready to get started?</p>',
                text: 'Ready to get started?',
              },
              card_icon: null,
              card_title: {
                html: '<p>Break ground</p>',
                text: 'Break ground',
              },
            },
            {
              card_content: {
                html:
                  '<p>We don&#39;t believe in one project stands. We strive to form long term, fun and rewarding relationships with likeminded clients that appreciate great UX and technology (hopefully you).</p>',
                text:
                  "We don't believe in one project stands. We strive to form long term, fun and rewarding relationships with likeminded clients that appreciate great UX and technology (hopefully you).",
              },
              card_cta_link: {
                url: '/contact-us/',
              },
              card_cta_text: {
                html: '<p>Swipe left</p>',
                text: 'Swipe left',
              },
              card_icon: null,
              card_title: {
                html: '<p>No casual hook ups</p>',
                text: 'No casual hook ups',
              },
            },
            {
              card_content: {
                html:
                  '<p>We bring an abundance of knowledge when we pair up with a partner team from working on a vast array of different projects. However, we are our best when we work with the best (hopefully you).</p>',
                text:
                  'We bring an abundance of knowledge when we pair up with a partner team from working on a vast array of different projects. However, we are our best when we work with the best (hopefully you).',
              },
              card_cta_link: {
                url: '/contact-us/',
              },
              card_cta_text: {
                html: '<p>Discover</p>',
                text: 'Discover',
              },
              card_icon: null,
              card_title: {
                html: '<p>Grow together</p>',
                text: 'Grow together',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
