const data = {
  name: 'CaseStudiesSnapshot',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            cta_link: {
              url: '',
            },
            cta_text: {
              text: '',
              html: '<p></p>',
            },
            icon: 'Base Ball Man',
            tagline: {
              text: 'Home runs',
              html: '<p>Home runs</p>',
            },
            title: {
              html: '<p>We always smash it outta the park</p>',
              text: 'We always smash it outta the park',
            },
          },
        },
      },
    },
  ],
};

export default data;
