const data = {
  name: 'InfoCard',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            darkmode: true,
            disable_onvisible: true,
            description: {
              text:
                ':Different contacted Woolly Mammoth to help design, build, and develop their marketing platform. We worked alongside their marketing and technical teams and helped oversee a recent redesign, new brand rollout, and developed a growth strategy centered around conversion tools that utilise best in class UX, CRO, and frontend development principles. We continue to work closely with :Different as they disrupt the property management industry with their unique product and proposition. Technologies used: ReactJS, Prismic, Gatsby, Fauna, Segment, Cypress',
              html:
                '<p>:Different contacted Woolly Mammoth to help design, build, and develop their marketing platform. We worked alongside their marketing and technical teams and helped oversee a recent redesign, new brand rollout, and developed a growth strategy centered around conversion tools that utilise best in class UX, CRO, and frontend development principles.</p><p>We continue to work closely with :Different as they disrupt the property management industry with their unique product and proposition.</p><p><strong>Technologies used:</strong></p><p>ReactJS, Prismic, Gatsby, Fauna, Segment, Cypress</p>',
            },
            cta_link: {
              url: 'https://different.com.au/',
            },
            cta_text: {
              text: 'Visit site',
            },
            image: {
              url:
                'https://images.prismic.io/woolly-mammoth-v2/5f92b91f-d9ff-4688-9143-6ff6805e48c7_wm-different.jpg?auto=compress%2Cformat&rect=0%2C0%2C6000%2C4500&w=2000&h=1500',
              alt: ':Different UX Design',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    backgroundColor: '#f85868',
                    images: {
                      fallback: {
                        src:
                          '/static/e4d53255f43a92ebaf026ba9fdba6515/7a84a/5f92b91f-d9ff-4688-9143-6ff6805e48c7_wm-different.jpg',
                        srcSet:
                          '/static/e4d53255f43a92ebaf026ba9fdba6515/2070e/5f92b91f-d9ff-4688-9143-6ff6805e48c7_wm-different.jpg 400w,\n/static/e4d53255f43a92ebaf026ba9fdba6515/d0b9c/5f92b91f-d9ff-4688-9143-6ff6805e48c7_wm-different.jpg 800w,\n/static/e4d53255f43a92ebaf026ba9fdba6515/7a84a/5f92b91f-d9ff-4688-9143-6ff6805e48c7_wm-different.jpg 1600w',
                        sizes: '(min-width: 1600px) 1600px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/e4d53255f43a92ebaf026ba9fdba6515/f5c71/5f92b91f-d9ff-4688-9143-6ff6805e48c7_wm-different.webp 400w,\n/static/e4d53255f43a92ebaf026ba9fdba6515/0d27e/5f92b91f-d9ff-4688-9143-6ff6805e48c7_wm-different.webp 800w,\n/static/e4d53255f43a92ebaf026ba9fdba6515/b924f/5f92b91f-d9ff-4688-9143-6ff6805e48c7_wm-different.webp 1600w',
                          type: 'image/webp',
                          sizes: '(min-width: 1600px) 1600px, 100vw',
                        },
                      ],
                    },
                    width: 1600,
                    height: 1200,
                  },
                },
              },
            },
            title: {
              html: '<p>:Different</p>',
              text: ':Different',
            },
          },
          items: [
            {
              service: 'UX/UI',
            },
            {
              service: 'Web development',
            },
          ],
        },
      },
    },
  ],
};

export default data;
