const data = {
  name: 'ClientLogos',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            content: {
              html: '<p>Woolly Mammoth is proud to have partnered with these Australian and International brands</p>',
              text: 'Woolly Mammoth is proud to have partnered with these Australian and International brands',
            },
            sub_title: {
              html: '<p>Meet the extended herd</p>',
              text: 'Meet the extended herd',
            },
            title: {
              html: '<p>Partners in crime</p>',
              text: 'Partners in crime',
            },
          },
        },
      },
    },
  ],
};

export default data;
