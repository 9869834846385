const data = {
  name: 'TeamCarousel',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
          },
          items: [
            {
              bio: {
                html:
                  '<p>At the heart of Woolly Mammoth, founder Sam Logan seamlessly combines years of experience in the design, development, and user experience of websites and applications with a relentless talent for problem-solving, feature management, and team integration.</p>',
                text:
                  'At the heart of Woolly Mammoth, founder Sam Logan seamlessly combines years of experience in the design, development, and user experience of websites and applications with a relentless talent for problem-solving, feature management, and team integration.',
              },
              job_title: {
                html: '<p>Original Mammoth</p>',
                text: 'Original Mammoth',
              },
              name: {
                html: '<h2>Sam Logan</h2>',
                text: 'Sam Logan',
              },
              portrait: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/84fa79bb-dc12-44dd-8558-445497d7d430_sam-logan.png?auto=compress%2Cformat&rect=0%2C0%2C3000%2C1826&w=2400&h=1461',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB50lEQVQoz5XTS2gTURSA4fFFC9ZHa0xr3LgRcSluXLSF0oq6ErHgwoVL3WRlFYtiUYkliyDUB+2m9pEiDpRoNQpasILRGlKqCUlN2kYwNE0zk5lJmij19XcyixIDOnFxucOZO98958y9gqxlyWRzFOfiUHLLKMsF0qpG+bvis9kQJDWLpH+cVlQj8DmRIBKdYVHOGLFStCJwUVZYWJJIShkSqTSDrhuM9nQRCLzVF+SNzf4LjCeSRGLzhD7NEo7O0d/VQdBzn1gowJKqQyVgJagQmY0zOTXNhO8dc18W6Hdc5UWvE1VOIWl/gsZsBob17CanPhio/2OYTvs5xFtXEEWRZFpGy+fJlGX2L1QIzsQI6aX23OvDYrGxy9rAsdYWtm+z0NjUQnQ+Tq7wtazkv5cu+KeDvHnv5/iJdgRBYKd1NzVbdlBba0VYV41nzMvKz19IilZZD5+9HOex9ykHDh5iw8ZqtuqZrd9UQ11dPYebmxkYGuH7b3RQrQx88tzLTaeTfXv3c/JIK+1H2zh7+hSuS3bEO06uXXewUgKa/hTP2COG3CMMdnfie3CbAcdFuu1nEF2XGR++y+uJV2QL39Z6aAo+HBX1I+PD7R5mj82Grb6BqqrNRj8vnO/gB5DSb83aWTQBVwF+AC630cI4rwAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/d9638188bdefe674ce3eaeaffc148d00/dd259/84fa79bb-dc12-44dd-8558-445497d7d430_sam-logan.png',
                          srcSet:
                            '/static/d9638188bdefe674ce3eaeaffc148d00/bb72b/84fa79bb-dc12-44dd-8558-445497d7d430_sam-logan.png 600w,\n/static/d9638188bdefe674ce3eaeaffc148d00/1ba41/84fa79bb-dc12-44dd-8558-445497d7d430_sam-logan.png 1200w,\n/static/d9638188bdefe674ce3eaeaffc148d00/dd259/84fa79bb-dc12-44dd-8558-445497d7d430_sam-logan.png 2400w',
                          sizes: '(min-width: 2400px) 2400px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/d9638188bdefe674ce3eaeaffc148d00/25b28/84fa79bb-dc12-44dd-8558-445497d7d430_sam-logan.webp 600w,\n/static/d9638188bdefe674ce3eaeaffc148d00/28f27/84fa79bb-dc12-44dd-8558-445497d7d430_sam-logan.webp 1200w,\n/static/d9638188bdefe674ce3eaeaffc148d00/3401d/84fa79bb-dc12-44dd-8558-445497d7d430_sam-logan.webp 2400w',
                            type: 'image/webp',
                            sizes: '(min-width: 2400px) 2400px, 100vw',
                          },
                        ],
                      },
                      width: 3000,
                      height: 1826.25,
                    },
                  },
                },
              },
            },
            {
              bio: {
                html:
                  '<p>Cassie is a front-end engineer at Woolly Mammoth; specialising in JAM stack technologies, UX, and Design. With a passion for both developer and user experience, Cassie builds clean, reusable, and delightful applications from our office in Sydney. </p>',
                text:
                  'Cassie is a front-end engineer at Woolly Mammoth; specialising in JAM stack technologies, UX, and Design. With a passion for both developer and user experience, Cassie builds clean, reusable, and delightful applications from our office in Sydney. ',
              },
              job_title: {
                html: '<p>Software Engineer</p>',
                text: 'Software Engineer',
              },
              name: {
                html: '<h2>Cassie Sloan</h2>',
                text: 'Cassie Sloan',
              },
              portrait: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/11567ce0-dfa8-4878-8c73-fcc2b0a0b64d_cassie-sloan.png?auto=compress%2Cformat&rect=0%2C0%2C3000%2C1826&w=2400&h=1461',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0klEQVQoz5WSTUjTcRyH/9BlSi+UrRe1g0EeJKFjQZc62aFzILRDdBDf0lCSEIKSsFNEL5oozXRaJhq0JSznqEamKPmasgLNbW77v2+j89PPxVwiOXf48OMHXx6e74ukmjFSUQwz+WrxBLJupP+xOP/Wbfz/FykFimoGshFjLRRm2f+TsKoT1fVtwJ1gSWBE1ViXVUJRhYgew/mijZ7bN/AvLSOb5qblrg1XAuv8WF3D/yvI7Hc/z5vr8HY+YGl6CiXxWwCNbYbqTsAF0d7XmXm8Ph8T419orb7OZ8cz5rwuAsEVUZQQSbedseXJ+UVGRj/gcb7B3tlB7TUb9xsrudNYx+TYEEZcFJp/syvg+7GPeFyDtLbcw5Kbh/VIAQUnTiJJe3h6tx5TDaGYwjI1w0zADmE17n7Ly247Vms+h/Py2bf3kABKVNmuEAmuosazAD56/JD2tif09vdxuvQMOZb9AniQY0cLmfg2JwCJ5KbTM8yw5W6Hg55X/bR3dQnD4+TmHMAioBculhEIy+I+9S3ATJbSO7eHYaeLhltNFBWdori4hEIxw6qam+IuTcKKmtVxS+5PPnpfD1B26TJnz52n/KqNippq7I4+ItoGUMsK+AekMjFZ4NgIswAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/3f5251a140355d1e500cd62d252a9c61/dd259/11567ce0-dfa8-4878-8c73-fcc2b0a0b64d_cassie-sloan.png',
                          srcSet:
                            '/static/3f5251a140355d1e500cd62d252a9c61/bb72b/11567ce0-dfa8-4878-8c73-fcc2b0a0b64d_cassie-sloan.png 600w,\n/static/3f5251a140355d1e500cd62d252a9c61/1ba41/11567ce0-dfa8-4878-8c73-fcc2b0a0b64d_cassie-sloan.png 1200w,\n/static/3f5251a140355d1e500cd62d252a9c61/dd259/11567ce0-dfa8-4878-8c73-fcc2b0a0b64d_cassie-sloan.png 2400w',
                          sizes: '(min-width: 2400px) 2400px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/3f5251a140355d1e500cd62d252a9c61/25b28/11567ce0-dfa8-4878-8c73-fcc2b0a0b64d_cassie-sloan.webp 600w,\n/static/3f5251a140355d1e500cd62d252a9c61/28f27/11567ce0-dfa8-4878-8c73-fcc2b0a0b64d_cassie-sloan.webp 1200w,\n/static/3f5251a140355d1e500cd62d252a9c61/3401d/11567ce0-dfa8-4878-8c73-fcc2b0a0b64d_cassie-sloan.webp 2400w',
                            type: 'image/webp',
                            sizes: '(min-width: 2400px) 2400px, 100vw',
                          },
                        ],
                      },
                      width: 3000,
                      height: 1826.25,
                    },
                  },
                },
              },
            },
            {
              bio: {
                html:
                  '<p>Dale is Woolly Mammoth&#39;s server-side-superman! A backend engineer with a penchant for problem-solving. Dale works to weave the functionality of web apps, websites and native apps with our fabulous front ends to create clever, innovative, and performant builds. </p>',
                text:
                  "Dale is Woolly Mammoth's server-side-superman! A backend engineer with a penchant for problem-solving. Dale works to weave the functionality of web apps, websites and native apps with our fabulous front ends to create clever, innovative, and performant builds. ",
              },
              job_title: {
                html: '<p>Software Engineer</p>',
                text: 'Software Engineer',
              },
              name: {
                html: '<h2>Dale Thompson</h2>',
                text: 'Dale Thompson',
              },
              portrait: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/35ea1d85-191d-4452-b751-bdf81cb04628_dale-thompson.png?auto=compress%2Cformat&rect=0%2C0%2C3000%2C1826&w=2400&h=1461',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2klEQVQoz5XRz0uTcRzA8Z3qYLMVqJtzbsw1PEQd+gvqEF7qUHSSDuElEYvKIAZKs/ZUq4SuEWXSoENUh7KfDpRZszkX/pgThzhra7pn2zPdpDr07utDiqf27PDl8/3B98Xnh05WCmTyCiu5POlsTt0XSuvirLDxli2sqnFzX27pUhmZpR/LJJJpUpks8cR3Po58Jrkiq3jF4Nxigmh8gfnFb8SXUnR0nON4y1GePH3G2s9fauYVgZHZOUJTM4Sno3wKRehqa+Vtfx/+wefIq0XkvLIFakF1wa+TfBgJ8HLwDeFojHtuF6/u3yaXSbK80cdtZaux8H9UNxqOqODrIT9Do2Ncu3KR4RcPCYbGRW/TKMXSP0xjyYHxCYbHQrR3XqCm1sKhAwc523YGm83J4SMtzM7H1alvDqgs+F5k1+/zYbHYMRjqMJrsVO820tjopKpqLw8eDfD7D1vDKQs+9g3QdfmS+GwQYI3IrBmTyYbDsV+9670uVQZ6vRJ3vB5OnzyGXr8Hs7mJWlG6sd5O66kTxCYC5Ivr2qcsea5y91YvN12dVOsN1JvtIkMrZss+JHc30eA7AZa0gx7JTZ/Ug+t8Ozt27qKhoQmr1SlABzfcPcS++MmtaQf/Ar40G/NRC7A0AAAAAElFTkSuQmCC',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/1a4983d0ec4c1a99ad14e9fc36960c6f/dd259/35ea1d85-191d-4452-b751-bdf81cb04628_dale-thompson.png',
                          srcSet:
                            '/static/1a4983d0ec4c1a99ad14e9fc36960c6f/bb72b/35ea1d85-191d-4452-b751-bdf81cb04628_dale-thompson.png 600w,\n/static/1a4983d0ec4c1a99ad14e9fc36960c6f/1ba41/35ea1d85-191d-4452-b751-bdf81cb04628_dale-thompson.png 1200w,\n/static/1a4983d0ec4c1a99ad14e9fc36960c6f/dd259/35ea1d85-191d-4452-b751-bdf81cb04628_dale-thompson.png 2400w',
                          sizes: '(min-width: 2400px) 2400px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/1a4983d0ec4c1a99ad14e9fc36960c6f/25b28/35ea1d85-191d-4452-b751-bdf81cb04628_dale-thompson.webp 600w,\n/static/1a4983d0ec4c1a99ad14e9fc36960c6f/28f27/35ea1d85-191d-4452-b751-bdf81cb04628_dale-thompson.webp 1200w,\n/static/1a4983d0ec4c1a99ad14e9fc36960c6f/3401d/35ea1d85-191d-4452-b751-bdf81cb04628_dale-thompson.webp 2400w',
                            type: 'image/webp',
                            sizes: '(min-width: 2400px) 2400px, 100vw',
                          },
                        ],
                      },
                      width: 3000,
                      height: 1826.25,
                    },
                  },
                },
              },
            },
            {
              bio: {
                html:
                  '<p>Harry specialises in digital marketing, data analytics, and web development. He has spent most of his career with startups and small businesses, helping them scale marketing using data-driven decisions and unit economics. Harry enjoys helping businesses understand how to utilise technology for automating and streamlining business processes to save both time and money. </p>',
                text:
                  'Harry specialises in digital marketing, data analytics, and web development. He has spent most of his career with startups and small businesses, helping them scale marketing using data-driven decisions and unit economics. Harry enjoys helping businesses understand how to utilise technology for automating and streamlining business processes to save both time and money. ',
              },
              job_title: {
                html: '<p>Data and Digital Marketing</p>',
                text: 'Data and Digital Marketing',
              },
              name: {
                html: '<h2>Harry Peppit</h2>',
                text: 'Harry Peppit',
              },
              portrait: {
                url:
                  'https://images.prismic.io/woolly-mammoth-v2/aeb7e403-55da-4619-8a24-1d6d78cdb9f0_harry-peppitt.png?auto=compress%2Cformat&rect=0%2C0%2C3000%2C1826&w=2400&h=1461',
                alt: null,
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABuUlEQVQoz5WST28SQRjG98N51ngxHvwAnjR6MNFWExujIlWa1tp4QEUp9qD1IpFGEKQUG0sa2q1Cd0Xa3WXZ/wt+gZ+zGzWmRlkPT2beycwvzzvPK3mjMW44wvZDhq6P6XiiHsd1dO6PvxHdiRTtJ0nShjZfdTPWwcCiJ9ZSpYbcUbD84BcoKVTa6SpEkrsqhhOQzT7m3OkTvFgpoNte7PInLAlU2mzvUtvcotJo8sUYsjibJn32DK1qiQPT/qPticBGa5tS9T3F8juUQ4OV/DLnTx2n1axhuCGW5x9xOKHl+scWa7V1yvUN0bpKLvuQpdQ0qroftzxw3DikpC6lt/UGq6+LvHy1yp4IYm9fYVeWMUXilpAThLF+D+efDp89L5B98ogH9+dYK5cFKKSnGWimRSAeR7L/I21pfiHD00KefGGZmauX6RsmSl/jUABd4cz94TDpTEqZzB1y+RxvKlVSN2dof+rQ7fUxbRddQE1XuBN/GEETA+fn7pK5dolb16f4sN2OgbYXMLBsPsttLMfBOTKPfwfeSzGbvs2Fk8e4ceUi1Y2mCEYVjsb0lA7FhWk0XccdJUv5O7SUXfFdo+sXAAAAAElFTkSuQmCC',
                      },
                      images: {
                        fallback: {
                          src:
                            '/static/43a3c0680f9cef42e1987cf85ef897e8/dd259/aeb7e403-55da-4619-8a24-1d6d78cdb9f0_harry-peppitt.png',
                          srcSet:
                            '/static/43a3c0680f9cef42e1987cf85ef897e8/bb72b/aeb7e403-55da-4619-8a24-1d6d78cdb9f0_harry-peppitt.png 600w,\n/static/43a3c0680f9cef42e1987cf85ef897e8/1ba41/aeb7e403-55da-4619-8a24-1d6d78cdb9f0_harry-peppitt.png 1200w,\n/static/43a3c0680f9cef42e1987cf85ef897e8/dd259/aeb7e403-55da-4619-8a24-1d6d78cdb9f0_harry-peppitt.png 2400w',
                          sizes: '(min-width: 2400px) 2400px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/43a3c0680f9cef42e1987cf85ef897e8/25b28/aeb7e403-55da-4619-8a24-1d6d78cdb9f0_harry-peppitt.webp 600w,\n/static/43a3c0680f9cef42e1987cf85ef897e8/28f27/aeb7e403-55da-4619-8a24-1d6d78cdb9f0_harry-peppitt.webp 1200w,\n/static/43a3c0680f9cef42e1987cf85ef897e8/3401d/aeb7e403-55da-4619-8a24-1d6d78cdb9f0_harry-peppitt.webp 2400w',
                            type: 'image/webp',
                            sizes: '(min-width: 2400px) 2400px, 100vw',
                          },
                        ],
                      },
                      width: 3000,
                      height: 1826.25,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
