const data = {
  name: 'InfoImageCols',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            dark_mode: true,
            cta_link: {
              url: '/contact-us/',
            },
            cta_text: {
              html: '<p>Let&#39;s build something</p>',
              text: "Let's build something",
            },
            image: {
              url:
                'https://images.prismic.io/woolly-mammoth-v2/8af2fa66-1d55-4ad2-96ea-fe758f37c302_ux-design.jpg?auto=compress%2Cformat&rect=0%2C0%2C1470%2C1470&w=1000&h=1000',
              alt: 'UX Specialists',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    placeholder: {
                      fallback:
                        'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQL/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAZ+MeQdxhBo2jWZhX//EABsQAAIDAQEBAAAAAAAAAAAAAAECAAMREhNB/9oACAEBAAEFAujhvyeqQWN3dcbojkLPgUZ//8QAFhEAAwAAAAAAAAAAAAAAAAAAABAS/9oACAEDAQE/ASX/AP/EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEr/8QAIBAAAQMCBwAAAAAAAAAAAAAAAAEREgJBICIxMmGR4f/aAAgBAQAGPwLmxTJnub07HkJl0PMH/8QAHRABAAMAAwADAAAAAAAAAAAAAQARITFBURBhkf/aAAgBAQABPyHmB4D3EOFdsqURU1xU1BE8/UBGfj4Ax6Sgyf/aAAwDAQACAAMAAAAQw+AA/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREFH/2gAIAQMBAT8QiI7lZ//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQIBAT8QrLyI/8QAHhABAAIBBAMAAAAAAAAAAAAAAQARITFBYXFRscH/2gAIAQEAAT8QyxPcm7AW6ZpA8hliqkexGlDm01RrVQGwqw6qvsoCd8J7I4ZwoL5jbrtbrP/Z',
                    },
                    images: {
                      fallback: {
                        src:
                          '/static/38ca2844c4af9a9fdec3a9405f786336/0b6a0/8af2fa66-1d55-4ad2-96ea-fe758f37c302_ux-design.jpg',
                        srcSet:
                          '/static/38ca2844c4af9a9fdec3a9405f786336/d4e31/8af2fa66-1d55-4ad2-96ea-fe758f37c302_ux-design.jpg 203w,\n/static/38ca2844c4af9a9fdec3a9405f786336/395bf/8af2fa66-1d55-4ad2-96ea-fe758f37c302_ux-design.jpg 405w,\n/static/38ca2844c4af9a9fdec3a9405f786336/0b6a0/8af2fa66-1d55-4ad2-96ea-fe758f37c302_ux-design.jpg 810w',
                        sizes: '(min-width: 810px) 810px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/38ca2844c4af9a9fdec3a9405f786336/6f337/8af2fa66-1d55-4ad2-96ea-fe758f37c302_ux-design.webp 203w,\n/static/38ca2844c4af9a9fdec3a9405f786336/b8a49/8af2fa66-1d55-4ad2-96ea-fe758f37c302_ux-design.webp 405w,\n/static/38ca2844c4af9a9fdec3a9405f786336/e64aa/8af2fa66-1d55-4ad2-96ea-fe758f37c302_ux-design.webp 810w',
                          type: 'image/webp',
                          sizes: '(min-width: 810px) 810px, 100vw',
                        },
                      ],
                    },
                    width: 810,
                    height: 810,
                  },
                },
              },
            },
            large_text: {
              html:
                '<p>So how do we stand out? In a nutshell, it’s by combining our unrelenting pixel perfect design work and the cutting-edge code it takes to bring it all together.</p><p>We strive to stay at the forefront of the latest breakthroughs in technology to ensure the digital products and user interfaces we create are hyper-performant, easy to use, look great, and represent your brand in the best way possible.</p>',
              text:
                'So how do we stand out? In a nutshell, it’s by combining our unrelenting pixel perfect design work and the cutting-edge code it takes to bring it all together. We strive to stay at the forefront of the latest breakthroughs in technology to ensure the digital products and user interfaces we create are hyper-performant, easy to use, look great, and represent your brand in the best way possible.',
            },
            small_text: {
              html:
                '<p>Wait a minute, Doc. Are you telling me you built a hyper-performant website... out of a DeLorean?</p>',
              text: 'Wait a minute, Doc. Are you telling me you built a hyper-performant website... out of a DeLorean?',
            },
            small_text_gif: {
              url:
                'https://images.prismic.io/woolly-mammoth-v2/ac04daaf-4075-4963-b097-3280667bb810_giphy+%285%29.gif?auto=compress,format',
            },
            title: {
              html: '<p>We design and develop exceptional user experiences across all mediums.</p>',
              text: 'We design and develop exceptional user experiences across all mediums.',
            },
          },
        },
      },
    },
  ],
};

export default data;
